import React from 'react';
import { 
    List,
    Filter, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    FunctionField, 
    SearchInput, 
    EditButton, 
    // ShowButton, 
    Pagination,
    useGetIdentity,
} from 'react-admin';
import ToggleRowStyle from './Common';
import { supabaseClient } from './supabase';

const StudentFilter = (props) => {
    return props.role !== 'parent' ? (
        <Filter {...props}>
            <SearchInput placeholder='Student last name' source='last_name' resettable alwaysOn />
            <SearchInput placeholder='Student first name' source='first_name' resettable alwaysOn />
        </Filter>
    ) : (<></>);
}

const StudentList = (props) => {
    const { data: identity, isLoading: identityLoading, error: identityError } = useGetIdentity();
    if (identityLoading) return <>Loading</>;
    if (identityError) return (<>Error</>);
    console.log('StudentList -> identity: ' + JSON.stringify(identity));
    // return false;
    // const { identity } = useGetIdentity();
    
    return (
        <List {...props} filters={<StudentFilter role={identity?.role} />} filter={identity?.role === 'teacher' ? { class_id: identity?.class_id } : identity?.role === 'parent' ? { household_hash: identity?.household_hash } : {}} title={ identity?.role === 'parent' ? 'My children' : 'List of students'}>
            <Pagination />
            <Datagrid rowStyle={ToggleRowStyle} bulkActionButtons={false}>
                <TextField source='last_name' />
                <TextField source='first_name' />
                <ReferenceField source='class_id' reference='classes'>
                    <TextField source='class_code' />
                </ReferenceField>
                {["admin","teacher"].includes(identity?.role) &&
                    <>
                        <ReferenceField label='Parent' source='parent_id' reference='parents'>
                            <FunctionField render={record => `${record.first_name} ${record.last_name}`} />
                        </ReferenceField>
                        <EditButton />
                    </>
                }
                {/* <ShowButton />           */}
            </Datagrid>
        </List>
    )
}

export default StudentList;;
