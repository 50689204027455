import React, { useEffect } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
    useLogin, 
    useNotify,
} from 'react-admin';
import { styles } from './Styles'
import LoginButton from './LoginButton';
import { MyForgotPasswordPage } from './MyForgotPasswordPage';

export const MyLoginForm = ({ theme }) => {
    // const [email, setEmail] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    
    // const location = useLocation();
    // useEffect(() => {
    //     console.log(`location.state in Side Effect: ${location.state}`);
    //     if (location.state?.message) {
    //         notify(location.state.message, { type: 'success'});
    //     }
    // }, [location]);

    const handleSubmit = async (e) => {
        if (email && password) {
            e.preventDefault();
            await login({ email, password }).catch((error) =>
                notify(`${error}`)
            );
        } else {
            notify('Please fill in your email address and password.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={styles.window}>
                <h2>Teken In</h2>
                <input
                    name="email"
                    type="email"
                    value={email}
                    placeholder='email address'
                    onChange={e => setEmail(e.target.value)}
                />
                <input
                    name="password"
                    type="password"
                    value={password}
                    placeholder='password'
                    onChange={e => setPassword(e.target.value)}
                />
                <LoginButton title="Teken in" />
                <a 
                    style={{
                        fontSize: '10pt',
                        marginTop: '8px',
                        color: 'grey'
                    }}
                    href={MyForgotPasswordPage.path}
                >
                    Wagwoord vergeet?
                </a>
            </div>
        </form>
    )
}
