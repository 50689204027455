import { Button } from '@mui/material';

const MediaButton = (props) => {
    const { mediaType, mediaPath, mediaTitle, handleClick } = props;
    return (mediaType !== 'V' && (
      <Button 
        href={mediaPath} target={'_new'}
      >
        {mediaTitle}
      </Button>
    )) || (
      <Button
        href=""
        onClick={() => handleClick(mediaPath)}
      >
        {mediaTitle}
      </Button>
    )
  }

  export default MediaButton;