import React from 'react';
import { 
    List, 
    Filter, 
    Datagrid, 
    TextField, 
    SearchInput, 
    EditButton, 
    // ShowButton, 
    Pagination, 
    useGetIdentity
} from 'react-admin';
import ToggleRowStyle from './Common';

const SubjectFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder='Subject name' source='name' resettable alwaysOn />
        {/* <SearchInput placeholder='Class code' source='code' resettable alwaysOn /> */}
    </Filter>
);

const SubjectList = (props) => {
    const { identity } = useGetIdentity();
    
    return (
    <List {...props} filters={identity.role === 'admin' ? <SubjectFilter /> : null} filter={identity.role === 'teacher' ? { id: identity.class_id } : {}} title='List of classes'>
        <Pagination />
        <Datagrid rowClick={(id, basePath, record) => {}} rowStyle={ToggleRowStyle} bulkActionButtons={false}>
            {/* <TextField source='alternatives' /> */}
            <TextField source='category' />  
            <TextField source='subject' />     
            
            <EditButton />
            {/* <ShowButton /> */}
        </Datagrid>
    </List>
)}

export default SubjectList;