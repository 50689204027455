import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  // FunctionField,
  // ReferenceField,
  SearchInput,
  // useRecordContext,
  EditButton,
  Pagination,
  useGetIdentity,
  useRecordContext,
  useGetList,
  useGetOne,
  useGetMany,
  useGetManyReference,
  SavedQueriesList,
  FilterLiveSearch,
  FilterList,
  Loading,
  FilterListItem,
} from 'react-admin';
import { Button, Card, CardContent } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import ToggleRowStyle from './Common';

const ResourceFilterSideBar = (props) => {
    const { data, isLoading, error, } = useGetList('resource_types', {
        pagination: { page: 1, perPage: 20 },
        sort: { field: 'name', order: 'ASC' },
    });
    if (isLoading) return <Loading />;
    if (error) return '<p>Error</p>';

    return (
        <Card sx={{ order: -1, mr: 2, mt: 8, width: 380 }}>
            <CardContent>
            <FilterList label="Resource sections" icon={<CategoryIcon />}>
                {data.map((record) => {
                return (
                    (                   
                    <FilterListItem
                        sx={{ borderLeft: `10px solid ${record.color}`, margin: 1 }}
                        label={record.name}
                        value={{ resource_type_id: record.id }}
                        key={record.id}
                    />
                    )
                );
                })}
            </FilterList>
            </CardContent>
        </Card>
    );
}

const ResourceList = (props) => {
    const {
      data: identity, isLoading: isIdentityLoading, error: identityError,
    } = useGetIdentity();
    if (isIdentityLoading) return <>Loading....</>;
    if (identityError) return <>Error...</>;
    return (
      <List
        {...props}
        aside={<ResourceFilterSideBar />}
        title="List of resources"      
        sx={{
          '& .RaList-actions': {
            maxWidth: '10%',
            width: '10%',
            border: '1px solid red',
          },
        }}
      >
        <Pagination />
        <Datagrid
          rowStyle={ToggleRowStyle}
          bulkActionButtons={false}
          key={(record) => `${record.id}`}
          rowClick={'show'}
        >
            <TextField source="title" />
            {['admin', 'teacher'].includes(identity.role) ? (
              <>
                  <EditButton />
              </>
            ) : null}
        </Datagrid>
      </List>
    );
  };
  
  export default ResourceList;