import React from 'react';
import ReactPlayer from 'react-player/lazy';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  playerWrapper: {
    position: 'relative',
    //paddingTop: '56.25%',
    width: '100%',
  },
  reactPlayer: {
    //position: 'absolute',
    top: 0,
    left: 0,
  },
}));

const VideoModal = ({ isOpen, handleClose, videoUrl }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth="lg"
      fullWidth
    >
      {/* <button onClick={handleClose}>Close</button> */}
      <div className={classes.playerWrapper}>
        <ReactPlayer
          key={videoUrl}
          url={videoUrl}
          playing={true}
          controls
          width="100%"
          height="100%"
          className={classes.reactPlayer}
        />
      </div>
    </Dialog>
  );
};

export default VideoModal;
