import React from 'react';
import { useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { styles } from './Styles'
import LoginButton from './LoginButton';
import { useResetPassword } from 'ra-supabase-core';
import { MyLoginPage } from './MyLoginPage';
import { supabaseClient } from './supabase';

const MyForgotPasswordForm = () => {
    console.log(`MyForgotPasswordForm(): SITE_URL = ${supabaseClient.SITE_URL}`);
    const [email, setEmail] = useState('');
    const notify = useNotify();
    const navigate = useNavigate();
    const [resetPassword] = useResetPassword({
        onError: error => {
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                {
                    type: 'warning',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    },
                }
            );
        },
        onSuccess: () => {
            notify('Please check your email for the "reset password" link.', { type: 'success' });
            navigate('/login');
        }
    });
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(`MyForgotPasswordForm.handleSubmit(): SITE_URL = ${supabaseClient.SITE_URL}`);
        if (email) {
            console.log(`The email value in state: ${email}`);
            return resetPassword({
                email: email,
                redirectTo: `${supabaseClient.SITE_URL}/auth-callback`,
            });
        } else {
            console.log(`Please fill in your email address`);
            notify('Please fill in your email address.', { type: 'warning'});
        }
        return false;
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={styles.window}>
                <h2>Wagwoord vergeet?</h2>
                <input
                    name="email"
                    id="email"
                    type="email"
                    value={email}
                    placeholder='e-pos adres'
                    onChange={e => setEmail(e.target.value)}
                />
                <LoginButton title="Hernu wagwoord" />
                <a 
                    style={{
                        fontSize: '10pt',
                        marginTop: '8px',
                        color: 'grey'
                    }}
                    href={MyLoginPage.path}
                >
                    Teken in!
                </a>
            </div>
        </form>
    )
}

export default MyForgotPasswordForm;