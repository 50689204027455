import React, { useEffect, useState } from "react";

import {
    Edit,
    FileField,
    FileInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import BackButton from "./BackButton";
import { useParams } from 'react-router-dom';
import { supabaseClient } from './supabase';

const LessonItemEdit = (props) => {
    const [signedUrl, setSignedUrl] = useState(null);
    const [tempLessonItem, setTempLessonItem] = useState(null);
    const [lessonItem, setLessonItem] = useState(null);
    const { id, lessonItemId } = useParams();
    const getSignedUrl = async (file) => {
        console.log(`Passed in file prop inside getSignedURL: ${file}`);
        try {
            const { data: media, error: mediaError } = await supabaseClient.storage
                .from('media')
                .createSignedUrl(`${file}`, 3600, { download: `${file}`});
            if (mediaError) throw new Error(mediaError.message);
            setSignedUrl(media.signedUrl);
            // return media.signedUrl;
        } catch (err) {
            console.log(`Caught error inside getSignedURL: ${err.message}`);
            return null;
        }
    }
    const getLessonItem = async (lessonItemId) => {
        console.log(`lessonItemId inside getLessonItems: ${lessonItemId}`);
        const { data: record, error } = await supabaseClient
            .from("lesson_items")
            .select(`
                id,
                title,
                description,
                file,
                lesson_id,
                type
            `)
            .match({ id: lessonItemId}).single();
        if (error) throw new Error(`Error fetching the students: ${error.message}`);
        console.log(`record inside getLessonItems: ${JSON.stringify(record)}`);
        setTempLessonItem(record);
    }
    // const fetchSignedUrl = () => signedUrl;

    useEffect(() => {
        console.log(`lessonItemId inside useEffect: ${lessonItemId}`);
        getLessonItem(lessonItemId);
    }, [lessonItemId]);
    useEffect(() => {
        if (tempLessonItem !== null && tempLessonItem.file) {
            console.log(`tempLessonItem inside useEffect(): ${JSON.stringify(tempLessonItem)}`);        
            getSignedUrl(tempLessonItem.file);            
        }
    }, [tempLessonItem]);
    useEffect(() => {
        if (signedUrl !== null) {
            setLessonItem({
                ...tempLessonItem,
                media: {
                    title: tempLessonItem.file,
                    src: signedUrl
                }
            });
        }
    }, [signedUrl, tempLessonItem]);
    console.log(`lessonItem outside useEffect(): ${JSON.stringify(lessonItem)}`);
    console.log(`signedUrl: ${signedUrl}`);

    // return signedUrl && (
    return (
        <Edit resource="lesson_items" id={lessonItemId} redirect={`/lessons/${id}/lessonItems`} {...props} title="Edit lesson">
            <SimpleForm record={lessonItem}>
                <TextInput source="title" />
                <RichTextInput source="description" />
                <FileInput source="media" multiple={false}>
                    <FileField source="src" title="title" />
                </FileInput>
                {/* <WithRecord label="Current file" render={record => <><label>Current file</label><a href={signedUrl} target="_new">{record.file}</a></>} /> */}
            </SimpleForm>
            <BackButton />
        </Edit>
    )
};

export default LessonItemEdit;
