import React from "react";

import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

const StudentEdit = (props) => (
    <Edit {...props} title="Edit student">
        <SimpleForm>
            <TextInput disabled source="id" style={{ display: 'none'}} />
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <ReferenceInput label="Class" source="class_id" reference="classes">
                <SelectInput optionText="class_code" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default StudentEdit;