


const checkMissingLink = (record) => {
    let foundMissingLink = false;
    if ([null,0].includes(record.subject_id)) foundMissingLink = true;
    if ([null,0].includes(record.class_id)) foundMissingLink = true;
    return foundMissingLink;
}

const ToggleRowStyle = (record, index) => ({
    // backgroundColor: record.class_id === 0 || record.class_id === null || record.subject_id ===0 || record.subject_id === null  ? 'lightpink' : (index % 2 === 0) ? '#FFFFFF' : '#E8E8E8'
    backgroundColor: checkMissingLink(record)  ? 'lightpink' : (index % 2 === 0) ? '#FFFFFF' : '#E8E8E8'
    // backgroundColor: (index % 2 === 0) ? '#FFFFFF' : '#E8E8E8' // TODO: Use the above line and remove this one before deplaoying
});

export default ToggleRowStyle;