function getWeekNumber(date) {
    const validDate = new Date(date);
    const firstJan = new Date(validDate.getFullYear(), 0, 1);
    const dayOfYear = ((validDate - firstJan + 86400000) / 86400000); // Days since January 1st
    return Math.ceil((dayOfYear - (validDate.getDay() || 7) + 1) / 7);
  }
  
  function getWeeksBetween(startDate, endDate) {
    const startWeek = getWeekNumber(startDate);
    const endWeek = getWeekNumber(endDate);
    const weeks = [];
  
    for (let week = startWeek; week <= endWeek; week++) {
        weeks.push(week);
    }
  
    return weeks;
  }
  
  function getWeeksPerTerm(terms) {
    const weeksPerTerm = {}
    for (let term of terms) {
        weeksPerTerm[term.id] = getWeeksBetween(term.from_date, term.to_date);
    }
    return weeksPerTerm;
  }

  export default getWeeksPerTerm;