import React, { useEffect, useState } from "react";

import {
    Create,
    FileField,
    FileInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import BackButton from "./BackButton";
import { useParams } from 'react-router-dom';

const defaultLessonItemValues = (props) => {
        const { id } = props;
        return ({ 
        archived: localStorage.getItem('lessonsArchived') || false,
        lesson_id: id,
    });
}

const LessonItemCreate = (props) => {
        const id = useParams();
        return (
            <Create resource="lesson_items" redirect={`/lessons/${id}/lessonItems`} {...props} title="Create lesson">
                <SimpleForm defaultValues={defaultLessonItemValues(id)}>
                    <TextInput source="title" />
                    <RichTextInput source="description" />
                    <FileInput source="media" multiple={false}>
                        <FileField source="src" title="title" />
                    </FileInput>
                </SimpleForm>
                <BackButton />
            </Create>
        );
    }

export default LessonItemCreate;
