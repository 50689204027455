import React, { useState, useEffect } from 'react';
import {
  Show,
  SimpleShowLayout,
  useShowController,
  useGetManyReference,
} from 'react-admin';
import VideoModal from './VideoModal';
import MediaButton from './MediaButton';
import { 
  Card, 
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideocamIcon from '@mui/icons-material/Videocam';
import BackButton from './BackButton';
import { supabaseClient } from './supabase';

const LessonShow = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [lessonItemsWithURLs, setLessonItemsWithURLs] = useState([]);
  const [lessonItemsWithURLsLoading, setLessonItemsWithURLsLoading] = useState(false);
  const [lessonItemsWithURLsError, setLessonItemsWithURLsError] = useState(null);  

  const handleClick = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const getType = (type) => {
    const types = {
      T: <ImageIcon />,
      D: <ArticleIcon />,
      P: <PictureAsPdfIcon />,
      V: <VideocamIcon />,
    };
    return types[type];
  };

  const {
    record: lesson,
    isLoading: lessonLoading,
    error: lessonError,
  } = useShowController();
  const {
    data: lessonItems,
    isLoading: lessonItemsLoading,
    error: lessonItemsError,
  } = useGetManyReference('lesson_items', {
    target: 'lesson_id',
    id: lesson.id,
    sort: { field: 'title', order: 'ASC'}
  });  

  console.log(`LessonItems: ${JSON.stringify(lessonItems)}`);

  async function fetchLessonItemsWithUrls(lessonItems) {
    const newLessonItems = await Promise.all(lessonItems.map(async (lessonItem) => {
      const mustDownload = lessonItem.type !== 'V' ? { download: lessonItem.file } : {};
      const { data: media, error: mediaError } = await supabaseClient.storage
      .from('media')
      .createSignedUrl(`${lessonItem.file}`, 
        3600, 
        mustDownload
      );        
      if (mediaError) throw new Error('>>>>>>>> Throwing internal error in supabaseClient.storage...', mediaError.message);
      console.log(`media that is returned: ${JSON.stringify(media)}`);
      const path = media.signedUrl;
      return {
        ...lessonItem,
        url: path
      }
    }));
    return newLessonItems;
  };  

  useEffect(() => {
    if (lessonItems && lessonItems.length > 0) {
      setLessonItemsWithURLsLoading(true);
      fetchLessonItemsWithUrls(lessonItems)
        .then((updatedLessonItems) =>{
          setLessonItemsWithURLs(updatedLessonItems);
          setLessonItemsWithURLsLoading(false);        
        })
        .catch((error) => {
          console.log('Failed to fetch update lessons items:', error);
          setLessonItemsWithURLsError(error);
          setLessonItemsWithURLsLoading(false);
        });
      }
  }, [lessonItems]);
  
  console.log('lessonItemsWithURLs:',lessonItemsWithURLs);

  if (lessonLoading || lessonItemsLoading || lessonItemsWithURLsLoading) return <div>Loading....</div>;
  if (lessonError || lessonItemsError || lessonItemsWithURLsError) return <div>Error: {lessonItemsWithURLsError.message}</div>;

  return (
    <Show {...props} title="Show Lesson" actions={false}>
      <SimpleShowLayout> 
        {lesson.title && <h2 dangerouslySetInnerHTML={{ __html: lesson.title }} />}
        {lesson.description && <p dangerouslySetInnerHTML={{ __html: lesson.description}} />}
        {lessonItemsWithURLs.map((lessonItem) => {          
          if (['P', 'D', 'V'].includes(lessonItem.type))
            return (
              <Card sx={{ display: 'flex', width: '100%', padding: '2rem' }}>
                  <span style={{ paddingRight: '1em', alignSelf: 'center' }}>
                    {getType(`${lessonItem.type}`)}
                  </span>
                  <MediaButton 
                    mediaType={lessonItem.type}
                    mediaPath={lessonItem.url}
                    mediaTitle={lessonItem.file.split("$:$")[1] ?? lessonItem.file}
                    handleClick={handleClick} 
                  />
              </Card>
            );
        })}
        <VideoModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          videoUrl={videoUrl}
        />
        <BackButton />
      </SimpleShowLayout>
    </Show>
  );
};

export default LessonShow;
