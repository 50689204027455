import React from "react";
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';
import { Box } from '@mui/system';

const ParentEdit = (props) => {
    return (
        <Edit {...props} title="Edit student">
            <SimpleForm>
                <TextInput disabled source="id" style={{ display: 'none'}} />
                <TextInput source="first_name" />
                <TextInput source="last_name" />
                <TextInput source="email" />
                <TextInput source="mobile" />
            </SimpleForm>
            <Box sx={{ color: 'text.primary', fontSize: 32, fontWeight: 'medium',paddingLeft: '15px' }}>
                Children
            </Box>
            <ReferenceManyField label="Children" reference="students" target="household_hash" source="household_hash">
                <Datagrid bulkActionButtons={false}>
                    <TextField source='last_name' />
                    <TextField source='first_name' />
                    <ReferenceField source='class_id' reference='classes'>
                        <TextField source='class_code' />
                    </ReferenceField>
                </Datagrid>
            </ReferenceManyField>
        </Edit>
    )
};

export default ParentEdit;