import React, { useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const ColorPicker = ({ colors }) => {
    const [showPicker, setShowPicker] = useState(false);

    const form = useFormContext();

    const handleColorClick = (color) => {
        form.setValue('color', color, { shouldDirty: true });
        // onSelectColor(color);
        setShowPicker(false);
        return true;
    };

    return (
        <div>
            <span style={{ color: 'grey', fontSize: '10pt'}}>Color</span>
            <div style={{ border: '1px solid grey', width: 30, height: 30, backgroundColor: form.getValues().color, marginBottom: 8}} />
            <Button 
                onClick={(e) => { e.preventDefault(); setShowPicker(!showPicker)}}
                startIcon={<ColorLensIcon />}
            >
                Choose Color
            </Button>
            {showPicker && (
                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px' }}>
                    {colors.map(color => (
                        <div
                            key={color}
                            onClick={() => handleColorClick(color)}
                            style={{
                                width: '30px',
                                height: '30px',
                                backgroundColor: color,
                                margin: '5px',
                                cursor: 'pointer',
                                border: '1px solid grey'
                            }}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
