import React from "react";

import {
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';

const ClassEdit = (props) => (
    <Edit {...props} title="Edit class">
        <SimpleForm>
            <TextInput disabled source="id" style={{ display: 'none'}} />
            {/* <TextInput source="class_name" /> */}
            <TextInput source="class_code" />
            <TextInput source="grade" />
        </SimpleForm>
    </Edit>
);

export default ClassEdit;