import React from 'react';
import { 
    // Loading, 
    List, 
    Filter, 
    Datagrid, 
    TextField, 
    SearchInput, 
    EditButton, 
    Pagination, 
    // useRecordContext,
} from 'react-admin';
import ToggleRowStyle from './Common';

const ParentFilter = (props) => (
    <Filter {...props}>
        {/* <SearchInput placeholder='Parent ID' source='id' resettable alwaysOn /> */}
        <SearchInput placeholder='Parent last name' source='last_name' resettable alwaysOn />
        <SearchInput placeholder='Parent first name' source='first_name' resettable alwaysOn />
    </Filter>
);

const ParentList = (props) => {    
    return (
        <List {...props} filters={<ParentFilter />} title='List of parents'>
            <Pagination />
            <Datagrid 
                rowClick="expand" 
                rowStyle={ToggleRowStyle} 
                bulkActionButtons={false}
            >
                <TextField source='last_name' />
                <TextField source='first_name' />
                <TextField source='email' />
                <TextField source='mobile' textAlign='left' />
                <EditButton />        
            </Datagrid>
        </List>
    )
}

export default ParentList;