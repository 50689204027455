import React, { useState, useEffect } from 'react';
import {
  Show,
  SimpleShowLayout,
  useShowController,
} from 'react-admin';
import VideoModal from './VideoModal';
import MediaButton from './MediaButton';
import { 
  Card, 
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import ArticleIcon from '@mui/icons-material/Article';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideocamIcon from '@mui/icons-material/Videocam';
import BackButton from './BackButton';
import { supabaseClient } from './supabase';

const ResourceShow = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [resourceWithURLs, setResourceWithURLs] = useState([]);
  const [resourceWithURLsLoading, setResourceWithURLsLoading] = useState(false);
  const [resourceWithURLsError, setResourceWithURLsError] = useState(null);  

  const handleClick = (url) => {
    setVideoUrl(url);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const getType = (type) => {
    const types = {
      D: <ArticleIcon />,
      P: <ImageIcon />,
      V: <VideocamIcon />,
    };
    return types[type];
  };

  const {
    record: resource,
    isLoading: resourceLoading,
    error: resourceError,
  } = useShowController();
 
  console.log(`resource: ${JSON.stringify(resource)}`);

  async function fetchResourceWithUrls(resource) {
      const mustDownload = resource.type !== 'V' ? { download: resource.file } : {};
      const { data: media, error: mediaError } = await supabaseClient.storage
      .from('media')
      .createSignedUrl(`${resource.file}`, 
        3600, 
        mustDownload
      );        
      if (mediaError) throw new Error('>>>>>>>> Throwing internal error in supabaseClient.storage...', mediaError.message);
      console.log(`media that is returned: ${JSON.stringify(media)}`);
      const path = media.signedUrl;
      return {
        ...resource,
        url: path
      }
  };  

  useEffect(() => {
    if (resource) {
      setResourceWithURLsLoading(true);
      fetchResourceWithUrls(resource)
        .then((updatedResource) =>{
          setResourceWithURLs(updatedResource);
          setResourceWithURLsLoading(false);        
        })
        .catch((error) => {
          console.log('Failed to fetch update resource:', error);
          setResourceWithURLsError(error);
          setResourceWithURLsLoading(false);
        });
      }
  }, [resource]);

  if (resourceLoading || resourceWithURLsLoading) return <div>Loading....</div>;
  if (resourceError || resourceWithURLsError) return <div>Error: {resourceWithURLsError.message}</div>;
  console.log('resourceWithURLs:',resourceWithURLs);

  return resourceWithURLs && (
    <Show {...props} title="Show Resource" actions={false}>
      <SimpleShowLayout>
        <h2>{resourceWithURLs.title}</h2>
        {resourceWithURLs.description && <p dangerouslySetInnerHTML={{ __html: resourceWithURLs.description}} />}
        ['P', 'D', 'V'].includes(resource.type) && (
          <Card sx={{ display: 'flex', width: '100%', padding: '2rem' }}>
              <span style={{ paddingRight: '1em', alignSelf: 'center' }}>
                {getType(`${resourceWithURLs.type}`)}
              </span>
              <MediaButton 
                mediaType={resourceWithURLs.type}
                mediaPath={resourceWithURLs.url}
                mediaTitle={resourceWithURLs.title}
                handleClick={handleClick} 
              />
          </Card>
        );
        <VideoModal
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          videoUrl={videoUrl}
        />
        <BackButton />
      </SimpleShowLayout>
    </Show>
  );
};

export default ResourceShow;
