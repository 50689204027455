import React from "react";

import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

const StudentCreate = (props) => (
    <Create {...props} title="Edit student">
        <SimpleForm>
            {/* <TextInput disabled source="id" /> */}
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="email" />
            <TextInput source="mobile" />
            <ReferenceInput source="class_id" reference="classes">
                <SelectInput label="Class" source="class_id" optionText="class_code" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default StudentCreate;