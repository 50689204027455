import React, { useEffect, useState } from "react";

import {
    Edit,
    FileField,
    FileInput,
    Loading,
    SimpleForm,
    TextInput,
    useEditContext
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import BackButton from "./BackButton";
import { 
    useParams, 
} from 'react-router-dom';
import { supabaseClient } from './supabase';

const ResourceEdit = (props) => {
    const [signedUrl, setSignedUrl] = useState(null);
    const [tempResource, setTempResource] = useState(null);
    const [theResource, setTheResource] = useState(null);
    const { id } = useParams();
    // const { data: record, isLoading: recordIsLoading, error: recordError } = useEditContext();

    const getSignedUrl = async (file) => {
        if (file) {
            console.log(`Passed in file prop inside getSignedURL: ${file}`);
            try {
                const { data: media, error: mediaError } = await supabaseClient.storage
                    .from('media')
                    .createSignedUrl(`${file}`, 3600, {download: `${file}`});
                if (mediaError) throw new Error(mediaError.message);
                setSignedUrl(media.signedUrl);
                // return media.signedUrl;
            } catch (err) {
                console.log(`Caught error inside getSignedURL: ${err.message}`);
                setSignedUrl('');
                // return null;
            }
        } else {
            setSignedUrl('');
        }
    }
    const getResource = async (id) => {
        const { data: record, error } = await supabaseClient
            .from("resources")
            .select(`
                id,
                title,
                description,
                file,
                type
            `)
            .match({ id: id}).single();
        if (error) throw new Error(`Error fetching the records: ${error.message}`);
        console.log(`record inside getResources: ${JSON.stringify(record)}`);
        setTempResource(record);
    }
    // const fetchSignedUrl = () => signedUrl;

    useEffect(() => {
        console.log(`id inside useEffect: ${id}`);
        getResource(id);
    }, [id]);
    useEffect(() => {
        if (tempResource) {
            getSignedUrl(tempResource.file);            
        }
    }, [tempResource]);
    useEffect(() => {
        if (tempResource !== null) {
            setTheResource({
                ...tempResource,
                media: {
                    title: tempResource.file,
                    src: signedUrl
                }
            });
        }
    }, [signedUrl, tempResource]);
    

    // if (recordIsLoading) return <Loading />;
    
    
    console.log(`theResource outside useEffect(): ${JSON.stringify(theResource)}`);
    console.log(`signedUrl: ${signedUrl}`);
    return theResource && (
        <Edit resource="resources" id={theResource.id} {...props} title="Edit resource">
            <SimpleForm record={theResource}>
                <TextInput source="title" />
                <RichTextInput source="description" />
                <FileInput source="media" multiple={false}>
                    <FileField source="src" title="title" />
                </FileInput>
                {/* <WithRecord label="Current file" render={record => <><label>Current file</label><a href={signedUrl} target="_new">{record.file}</a></>} /> */}
            </SimpleForm>
            <BackButton />
        </Edit>
    )
};

export default ResourceEdit;
