import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const BackButton = (props) => {
    let navigate = useNavigate();
    return (
        <Button {...props}
        style={{ margin: '8px'}}
            href={''}
            onClick={() => navigate(-1) }
            startIcon={<ArrowCircleLeftIcon />}
        >
            Back
        </Button>
    )
}

export default BackButton;