import React from 'react';
import { 
    List,
    // ListActions,
    Datagrid, 
    TextField, 
    Pagination,
    useRecordContext,
    useGetIdentity,
    CreateButton,
    TopToolbar,
    Create,
    Empty
} from 'react-admin';
import EditIcon from '@mui/icons-material/ModeEdit';
import { Box, Button, Typography } from '@mui/material';
import BackButton from './BackButton';
import { useParams } from 'react-router-dom';
import ToggleRowStyle from './Common';
import { styled } from '@mui/material/styles';

const EditLessonItemButton = () => {
    const lessonItem = useRecordContext();
    return (
        <Button
            href={`/lessons/${lessonItem?.lesson_id}/lessonItems/${lessonItem?.id}`}
            startIcon={<EditIcon />}
        >
            Edit
        </Button>
    )
}

const ListActions = (props) => {
    const { lesson_id } = props;
    return (
        <TopToolbar>
            <CreateButton to={`/lessons/${lesson_id}/lessonItems/create`} />
        </TopToolbar>
    );
};

// const Empty = () => (
//     <Box textAlign="center" m={1}>
//         <Typography variant="h4" paragraph>
//             No Lesson Items available
//         </Typography>
//         <Typography variant="body1">
//             Create a Lesson Item
//         </Typography>
//         <CreateButton />
//     </Box>
// );

const MyEmpty = (props) => {
    const { className, lesson_id } = props;
    return (
        <Root className={className}>
            <Empty />
            <div className={EmptyClasses.toolbar}>
                <CreateButton 
                    variant="contained" 
                    to={`/lessons/${lesson_id}/lessonItems/create`}
                />
            </div>
        </Root>
    );
}

const LessonItemList = (props) => {
    const { id } = useParams();
    const { data: identity, isLoading: identityLoading, error: identityError } = useGetIdentity();
    if (identityLoading) return <>Loading...</>;
    if (identityError) return <>Error...</>
    return (
        <List {...props}
            actions={<ListActions lesson_id={id} />}
            empty={<MyEmpty lesson_id={id} />}
            resource="lesson_items"     
            title='List of lesson items'             
            filter={{ lesson_id: id }}
        >
            <Pagination />
            <Datagrid key={record => `${record.id}`} rowStyle={ToggleRowStyle} bulkActionButtons={false}>
                <TextField source='title' />            
                <TextField source='file' />            
                {["admin", "teacher"].includes(identity.role) 
                    ? (<><EditLessonItemButton /></>) 
                    : null 
                }                
            </Datagrid>
            <BackButton />
        </List>
    )
}

const PREFIX = 'RaEmpty';

export const EmptyClasses = {
    message: `${PREFIX}-message`,
    icon: `${PREFIX}-icon`,
    toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('span', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    flex: 1,
    [`& .${EmptyClasses.message}`]: {
        textAlign: 'center',
        opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
        margin: '0 1em',
        color:
            theme.palette.mode === 'light'
                ? 'inherit'
                : theme.palette.text.primary,
    },

    [`& .${EmptyClasses.icon}`]: {
        width: '9em',
        height: '9em',
    },

    [`& .${EmptyClasses.toolbar}`]: {
        textAlign: 'center',
        marginTop: '2em',
    },
}));

export default LessonItemList;
