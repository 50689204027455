import WindowBG from '../images/window_bg.png'
import BottomBG from '../images/bottom_bg.png'
import WallBG from '../images/loginbg.png'
import waardeSirkels from '../images/waardes.png'
import lgsLogo from '../images/lgsLogo.png'
import { alignProperty } from '@mui/material/styles/cssUtils'

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    alignItems: 'center',
    // paddingTop: '20px',
    // height: 'calc(100vh - 46px)',
    height: 'calc(100vh)',
    background: 'transparent url(' + WallBG + ')',
  },
  acrossContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingTop: '20px',
    // border: '1px solid red',
    // height: 'calc(100vh - 327px)'
    height: '100%'
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingTop: '20px'
    width: 300,
    height: 500,
    background: 'transparent url(' + waardeSirkels + ') no-repeat top left'
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'top',
    // alignItems: 'top',
    paddingTop: '40px',
    height: 500
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingTop: '20px'
    width: 300,
    height: 520,
    background: 'transparent url(' + lgsLogo + ') no-repeat top center',
    paddingBottom: '0px'
  },
  Entity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pelmet: {
    backgroundColor: '#7D3C1E',
    height: '32px'
  },
  window: {
    // maxWidth: '520px',
    minHeight: '262px',
    backgroundColor: '#ffffff',
    backgroundImage: 'url(' + WindowBG + ')',
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    // marginTop: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '32px solid #7D3C1E',
    borderLeft: '10px solid #FDE8CD',
    borderRight: '10px solid #FDE8CD',
    borderBottom: '10px solid #FDE8CD'
  },
  nametag: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '506px',
    backgroundColor: '#7D3C1E',
    padding: '4px',
    border: '4px solid black',
    borderRadius: '8px',
    fontSize: '2em',
    color: 'white'
  },
  couch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'bottom',
    width: 'calc(100vw)',
    // height: '100%',
    height: 500,
    background: 'transparent url(' + BottomBG + ') no-repeat top center',
    // border: '1px solid blue'
  },
  input: {
    height: 45,
    marginTop: 8,
    width: 300,
    maxWidth: 300,
    padding: '0px 8px',
    fontSize: 16,
    outline: 'none',
    border: 'none',
    borderBottom: '2px solid rgba(0, 0, 0, .3)'
  },
  toggleForm: {
    fontWeight: '600',
    padding: '0px 25px',
    marginTop: '15px',
    marginBottom: 0,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  resetPassword: {
    marginTop: '5px',
  },
  anchor: {
    color: '#006bfc',
    cursor: 'pointer'
  },
  dashContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingTop: '20px'
    // maxWidth: 800,
    height: 520,
    paddingBottom: '0px'
  },
}

