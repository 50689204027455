import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Carousel from './Carousel';

import { styles } from './Styles';
import Akker from '../images/akker.png';
import Preprimer from '../images/preprimer.png';
import Trust from '../images/loerietrust.png';
import Omgeeloeries from '../images/omgeeloeries.png';
import Ondersteuners from '../images/ondersteuners.png';
import Vlerke from '../images/vlerke.png';


import { Title } from 'react-admin';
import lgsLogo from '../images/lgsLogo.png';
import sfmLogo from '../images/SFM.jpg';
import d6Logo from '../images/d6Logo.svg';
import karriPayLogo from '../images/karriPayLogo.svg';
import  
{ 
  /* StaticSponsors, */
  MarqueeSponsors,
} from './Sponsors';
import Button from '@mui/material/Button';
// import Button from 'material-ui/core';
// import { Facebook, Instagram, Vimeo } from '@trejgun/material-ui-icons-social-networks';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import {
    Instagram,
    Facebook,
    Storefront,
    YouTube,
} from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import RadioIcon from '@mui/icons-material/Radio';
import Box from '@mui/material/Box';


/* 
    Anonymous default exports of anonymous arrow functions isn't allowed by the linting of ES2015+ (ES6+) import/export syntax.
    The `eslint import/no-anonymous-default-export` line just above the export removes the VS Code warning.
    Read for more: https://github.com/import-js/eslint-plugin-import/blob/v2.27.5/docs/rules/no-anonymous-default-export.md
*/

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default () => (
    <>
    <Card sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '15px' }}>
        <Title title="Voorwoord" />
        <CardContent sx={{ maxWidth: 800, textAlign: 'center' }}>
        <p>
            <strong>Baie welkom dan by ons Skool in die huis, Die Loerieweb.</strong>
        </p>
        <p>
            Bestudeer die Tutorvideo vir die gebruik van hierdie platform en hoe u as gesin hierby gaan baat.
        </p>
        <p>
            Ons Mantra: Droom groot, werk hard en gee om.
        </p>
        <p>
            Laerskool George-Suid, Die strewe na uitnemendheid.
        </p>
        <div style={styles.dashContainer}>
            <div style={styles.Entity}>
                <a href="https://lgs.co.za/nasorg/" target="_blank"><img src={Akker} width={140} /></a>
                <a href="https://lgs.co.za/entiteite/" target="_blank"><img src={Trust} width={140} /></a>
                <a href="https://lgs.co.za/entiteite/" target="_blank"><img src={Vlerke} width={140} /></a>
            </div>
            <div style={styles.Entity}>
                <a href="https://lgs.co.za/george_suid_pre_primer/" target="_blank"><img src={Preprimer} width={140} /></a>
                <a href="https://lgs.co.za/entiteite/" target="_blank"><img src={Omgeeloeries} width={140} /></a>
                <a href="https://lgs.co.za/ondersteuners/" target="_blank"><img src={Ondersteuners} width={140} /></a>
            </div>
        </div>
        </CardContent>
        <CardContent>
            <Stack direction="row" spacing={1} alignItems="flex-start">
                <img src={lgsLogo} alt="LGS Logo" width={160} />
                <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                    <img src={sfmLogo} alt="SFM Logo" width={200} />
                    <video height="35" width="320" controls name="media">
                        <source src="https://iceant.eclipse-streaming.co.za/SFM" type="audio/aac" />
                    </video>
                </Box>
            </Stack>
            <Container maxWidth="lg" sx={{ mt: 2 }}>
                <Stack direction="column" spacing={1} alignItems="baseline">
                    <Button sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://lgs.co.za/winkel/','_blank')}>
                      <Storefront color="primary" fontSize="large" />&nbsp;Besoek ons aanlyn winkel
                    </Button>
                    <Button sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://diskonto.net/','_blank')}>
                      <Storefront color="primary" fontSize="large" />&nbsp;Diskonto
                    </Button>
                    <Button variant="outline" sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://www.facebook.com/laerskoolgeorgesuid','_blank')}>
                        <Facebook color="primary" fontSize="large" />&nbsp;Facebook
                    </Button>
                    <Button variant="outline" sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://www.instagram.com/volbloedloeries/','_blank')}>
                        <Instagram color="primary" fontSize="large" sx={{ color: "#E1306C" }} />&nbsp;Instagram
                    </Button>
                    <Button variant="outline" sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://www.youtube.com/channel/UCiHoQJaBjo6UtgDvD12YspA','_blank')}>
                        <YouTube color="primary" fontSize="large" sx={{ color: "#CD201F" }} />&nbsp;YouTube
                    </Button>
                    <Button variant="outline" sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://www.youtube.com/channel/UCiHoQJaBjo6UtgDvD12YspA','_blank')}>
                        <img src={d6Logo} width="35" height="35" alt="D6 Connect"  />&nbsp;D6 Connect
                    </Button>
                    <Button variant="outline" sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://karripay.com/','_blank')}>
                        <img src={karriPayLogo} width="35" height="35" alt="Karri Pay"  />&nbsp;Karri Pay
                    </Button>
                    <Button variant="outline" sx={{ paddingX: '0px', width: '100%', justifyContent: 'left'}} onClick={() => window.open('https://lgs.co.za','_blank')}>
                        <LanguageIcon color="primary" fontSize="large" sx={{ color: '#FBC02D'}} />&nbsp;Laerskool George Suid
                    </Button>
                    {/* <Vimeo color="primary" fontsize="large" /> */}
                </Stack>
            </Container>
        </CardContent>
    </Card>
    <Carousel>
        <MarqueeSponsors />      
    </Carousel>
    </>
);
