import React from "react";

import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput
} from 'react-admin';

const TeacherEdit = (props) => (
    <Edit {...props} title="Edit teacher">
        <SimpleForm>
            <TextInput disabled source="id" style={{ display: 'none'}} />
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="email" />
            <TextInput source="mobile" />
            {/* <ReferenceInput label="Class" source="class_id" reference="classes" /> */}
            <ReferenceInput source="class_id" reference="classes">
                <SelectInput label="Class" source="class_code" />
            </ReferenceInput>
            <SelectInput source="role" choices={
                [
                    { 'id': 'teacher', 'name': 'Teacher' },
                    { 'id': 'trainer', 'name': 'Trainer' },
                    { 'id': 'principal', 'name': 'Principal' }
                ]
            } emptyText="Unknown" emptyValue="unknown" />
        </SimpleForm>
    </Edit>
);

export default TeacherEdit;