import React, { useState, useEffect } from 'react';
import { 
    useNotify,
    useAuthProvider
} from 'react-admin';
import { styles } from './Styles'
import LoginButton from './LoginButton';
import { useSetPassword, useSupabaseAccessToken } from 'ra-supabase-core';
import { useNavigate } from 'react-router-dom';
import { stringify } from './Utils';
import { MyLoginPage } from './MyLoginPage';
import { supabaseClient } from './supabase';

const MySetPasswordForm = () => {      
    const notify = useNotify();
    const navigate = useNavigate();
    const authProvider = useAuthProvider();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const access_token = useSupabaseAccessToken();
    const refresh_token = useSupabaseAccessToken({
        parameterName: 'refresh_token',
    });

    const [setPasswordFunction] = useSetPassword({
        onError: error => {
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                    ? 'ra.auth.sign_in_error'
                    : error.message,
                {
                    type: 'warning',
                    messageArgs: {
                        _:
                            typeof error === 'string'
                                ? error
                                : error && error.message
                                ? error.message
                                : undefined,
                    },
                }
            );
        },
        onSuccess: () => {
            notify(`Successfully updated the password!`, { type: 'success'});
            authProvider.logout();
            navigate('/login');
        }
    });
    // const [setPasswordFunction] = useSetPassword();    

    const handleSubmit = async (event) => {
        console.log(`This is handleSubmit in MySetPasswordForm.`);
        console.log(`password: ${password}`);
        console.log(`confirmPassword: ${confirmPassword}`);
        event.preventDefault();
  
        if (!password || !confirmPassword) {
            notify(`The passwords don't match! Please try again.`, { type: 'error'});
            return false;
        }

        if (password !== confirmPassword) {           
            console.log(`Passwords don't match.`);
            notify(`The passwords don't match! Please try again.`, { type: 'error'});
            return false;
        } 
        console.log(`Passwords match!`);

        setPasswordFunction({
            access_token: access_token,
            refresh_token,
            password: password,
        });
  
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={styles.window}>
                <h2>Stel wagwoord</h2>
                <input
                    name="password"
                    type="password"
                    placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    name="confirmPassword"
                    type="password"
                    placeholder='Confirm password'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <LoginButton title="Stel wagwoord" />
                <a 
                    style={{
                        fontSize: '10pt',
                        marginTop: '8px',
                        color: 'grey'
                    }}
                    href={MyLoginPage.path}
                >
                    Teken in!
                </a>
            </div>
        </form>
    )
}

export default MySetPasswordForm;