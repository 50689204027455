import React, { useEffect, useState } from "react";

import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField,
    useGetList
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { RichTextInput } from 'ra-input-rich-text';

const lessonDefaultValues = () => ({ archived: false });

function getWeekNumber(date) {
    const validDate = new Date(date);
    const firstJan = new Date(validDate.getFullYear(), 0, 1);
    const dayOfYear = ((validDate - firstJan + 86400000) / 86400000); // Days since January 1st
    return Math.ceil((dayOfYear - (validDate.getDay() || 7) + 1) / 7);
}

function getWeeksBetween(startDate, endDate) {
    const startWeek = getWeekNumber(startDate);
    const endWeek = getWeekNumber(endDate);
    const weeks = [];

    for (let week = startWeek; week <= endWeek; week++) {
        weeks.push(week);
    }

    return weeks;
}

function getWeeksPerTerm(terms) {
    const weeksPerTerm = {}
    for (let term of terms) {
        weeksPerTerm[term.id] = getWeeksBetween(term.from_date, term.to_date);
    }
    return weeksPerTerm;
}

const getWeeksChoices = (items) => items.map((item) => ({ id: item, name: `Week ${item}`}));

const WeekInput = (props) => {
    const { weeksPerTerm } = props;
    const term_id = useWatch({ name: "term_id"});
    return <SelectInput choices={term_id ? getWeeksChoices(weeksPerTerm[term_id]) : []} source="week" label="Week" />
}

const LessonCreate = (props) => {
    const [weeksPerTerm, setWeeksPerTerm] = useState({});
    const grades = [
        {id: 1, name: 'Grade 1'},
        {id: 2, name: 'Grade 2'},
        {id: 3, name: 'Grade 3'},
        {id: 4, name: 'Grade 4'},
        {id: 5, name: 'Grade 5'},
        {id: 6, name: 'Grade 6'},
        {id: 7, name: 'Grade 7'},
    ];
    const { data: terms } = useGetList('terms');
    useEffect(() => {        
        if (terms) setWeeksPerTerm(getWeeksPerTerm(terms));
    },[setWeeksPerTerm, terms]);
    
    console.log(`Terms: ${JSON.stringify(terms)}`);
    console.log(`weeksPerTerm: ${JSON.stringify(weeksPerTerm)}`);


    return (
        <Create {...props} title="Create lesson" redirect="list">
            <SimpleForm defaultValues={lessonDefaultValues}>
                <TextInput source="code" />
                <ReferenceInput label="Term" source="term_id" reference="terms">
                    <SelectInput />
                </ReferenceInput>
                <WeekInput weeksPerTerm={weeksPerTerm} />
                <SelectInput source="grade" choices={grades} />
                <ReferenceInput label="Subject" source="subject_id" reference="subjects">
                    <SelectInput />
                </ReferenceInput>
                <TextInput source="title" />
                <RichTextInput source="description"  />
                <FileInput label="New attachments" source="attachments" multiple={true} sx={{ boxShadow: '0px 0px 6px 4px darkgrey', padding: '0px 8px', '& span': { fontSize: '1.75em', color: 'darkred'}}}>
                    <FileField source="src" title="title" />
                </FileInput> 
            </SimpleForm>
        </Create>
    )
};

export default LessonCreate;