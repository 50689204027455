import React from 'react';
import { 
    List, 
    Filter, 
    Datagrid, 
    TextField, 
    SearchInput, 
    EditButton, 
    // ShowButton, 
    Pagination, 
    useGetIdentity,
} from 'react-admin';
import ToggleRowStyle from './Common';

const ClassFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder='Class name' source='name' resettable alwaysOn />
        <SearchInput placeholder='Class code' source='code' resettable alwaysOn />
    </Filter>
);

const ClassList = (props) => {
    const { identity } = useGetIdentity();
    return (
    <List {...props} filters={identity.role === 'admin' ? <ClassFilter /> : null} filter={identity.role === 'teacher' ? { id: identity.class_id } : {}} title='List of classes'>
        <Pagination />
        <Datagrid rowClick={(id, basePath, record) => {}} rowStyle={ToggleRowStyle} bulkActionButtons={false}>
            <TextField source='class_code' />            
            <TextField source='grade' />
            <EditButton />
            {/* <ShowButton /> */}
        </Datagrid>
    </List>
)}

export default ClassList;