import React from 'react';
import { styles } from './Styles'

export const AuthLayout = (props) => {
    const { children } = props;
    return (
        <div style={styles.container}>
            <div style={styles.acrossContainer}>
                <div style={styles.leftContainer}></div>
                <div style={styles.centerContainer}>
                    {children}
                    <div style={styles.nametag}>Die Loerieweb</div>
                </div>
                <div style={styles.rightContainer}>
                    &nbsp;
                </div>
            </div>
            <div style={styles.couch}>
                &nbsp;
            </div>                
        </div>
    )
}
