import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
} from 'react-admin';

const TermCreate = (props) => {    
    return (
        <Create {...props} title="Create Term">
            <SimpleForm>
                {/* <TextInput disabled source="id" /> */}
                <TextInput source="name" />
                <DateInput source="from_date" />
                <DateInput source="to_date" />
            </SimpleForm>
        </Create>
    )
};

export default TermCreate;