import React, { useEffect, useState } from "react";

import {
    Edit,
    SimpleForm,
    TextInput,
    SelectArrayInput,
} from 'react-admin';

import ColorPicker from "./ColorPicker";
import { Label } from "@mui/icons-material";

const colorList = [
    "aqua","aquamarine","black","blue","blueviolet","chartreuse","coral","cornflowerblue","crimson","darkmagenta","darkorchid",
    "darkturquoise","darkviolet","deeppink","deepskyblue","dodgerblue","fuchsia","gold","greenyellow","hotpink","indigo",
    "lavender","lawngreen","lightblue","lightcoral","lightgreen","lightpink","lightsalmon","lightseagreen","lightskyblue","lime",
    "limegreen","magenta","mediumaquamarine","mediumblue","mediumorchid","mediumpurple","mediumseagreen","mediumslateblue",
    "mediumspringgreen","mediumturquoise","mediumvioletred","orangered","orchid","palegreen","palevioletred","plum","powderblue",
    "rebeccapurple","royalblue","seagreen","skyblue","slateblue","springgreen","steelblue","teal","tomato","turquoise","violet","whitesmoke","yellowgreen"]; 

const SubjectEdit = (props) => {

    
       
    const grades = [
        {id: 1, name: 'Grade 1'},
        {id: 2, name: 'Grade 2'},
        {id: 3, name: 'Grade 3'},
        {id: 4, name: 'Grade 4'},
        {id: 5, name: 'Grade 5'},
        {id: 6, name: 'Grade 6'},
        {id: 7, name: 'Grade 7'},
    ];
    return (
    <Edit {...props} title="Edit class">
        <SimpleForm>
            {/* <TextInput disabled source="id" /> */}
            <TextInput source="category" />
            <TextInput source="subject" />
            {/* <TextInput source="alternatives" /> */}
            <SelectArrayInput source="grades" choices={grades} />
            <TextInput source="color" label="Color" style={{ display: 'none'}} />
            <div style={{ display: 'flex', alignItems: 'center'}}>
                <ColorPicker colors={colorList} />
            </div>
        </SimpleForm>
    </Edit>
)};

export default SubjectEdit;