import React from "react";

import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import BackButton from "./BackButton";


const ResourceCreate = (props) => (
    <Create {...props} title="Create resource">
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="title" />
            <RichTextInput source="description" />
            <ReferenceInput label="Resource type" source="resource_type_id" reference="resource_types">
                {/* <SelectInput source="name" /> */}
            </ReferenceInput>
            <FileInput source="media" multiple={false}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
        <BackButton />
    </Create>
);

export default ResourceCreate;