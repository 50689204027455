import { styled } from '@mui/system';

const Carousel = styled("div")({
    position: 'absolute',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    left: '0px',
    bottom: '0px',
    width: '100vw',
    height: '150px',
    padding: '0px',
    zIndex: 'auto',
    // border: '1px solid red',    
    justifyContent: 'center',
  });

export default Carousel;