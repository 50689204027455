import React from 'react';
import { 
        List, 
        Filter, 
        Datagrid, 
        TextField, 
        FunctionField,
        ReferenceField, 
        SearchInput, 
        EditButton, 
        // ShowButton, 
        Pagination
    } from 'react-admin';
import ToggleRowStyle from './Common';

const TeacherFilter = (props) => (
    <Filter {...props}>
        <SearchInput placeholder='Teacher last name' source='last_name' resettable alwaysOn />
        <SearchInput placeholder='Teacher first name' source='first_name' resettable alwaysOn />
    </Filter>
);

const TeacherList = (props) => (
    <List {...props} filters={<TeacherFilter />} title='List of teachers'>
        <Pagination />
        <Datagrid rowClick={(id, basePath, record) => {}} rowStyle={ToggleRowStyle} bulkActionButtons={false}>
            <TextField source='last_name' />
            <TextField source='first_name' />
            <TextField source='email' />
            <ReferenceField source='class_id' reference='classes' emptyText="No class">
                <TextField source='class_code' />
            </ReferenceField>
            <TextField source="role" />
            <EditButton />
            {/* <ShowButton /> */}
        </Datagrid>
    </List>
)

export default TeacherList;;