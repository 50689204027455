import React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceManyField,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';

const ParentCreate = (props) => {
    
    return (
        <Create {...props} title="Create Parent">
            <SimpleForm>
                <TextInput source="first_name" />
                <TextInput source="last_name" />
                <TextInput source="email" />
                <TextInput source="mobile" />
            </SimpleForm>
            <ReferenceManyField label="Children" reference="students" target="parent_id">
                <Datagrid bulkActionButtons={false}>
                    <TextField source='last_name' />
                    <TextField source='first_name' />
                    <TextField source='email' />
                    <TextField source='mobile' />
                    <ReferenceField source='class_id' reference='classes'>
                        <TextField source='class_code' />
                    </ReferenceField>
                </Datagrid>
            </ReferenceManyField>
        </Create>
    )
};

export default ParentCreate;